<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Hover &amp; Focus<br />Utilities</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Utilities for hover and focus behaviors.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <!-- <section>
            <div class="content-band" id="flex-containers">
              <h2>Hover</h2>
              <p class="mb-30">
                Use these classes to make an element lay out its content using the flexbox model. Each direct 
                child of the flex container will become a flex item.
              </p>

              <div class="mt-30 flex flex-col border-400 w800 hover-white hover-shadow">

              <div class="flex">
                <div class="container plr-30 ptb-20 basis-25 gray-250">Class</div>
                <div class="container plr-30 ptb-20 basis-75 gray-250">Description</div>
              </div>
              
              <div class="flex border-bottom">
                <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.hover-shadow </div>
                <div class="container plr-30 ptb-20 basis-75">The element behaves like a block and lays out its content using the flexbox model.</div>
              </div>

              <div class="flex border-bottom">
                <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.hover-border </div>
                <div class="container plr-30 ptb-20 basis-75">The element behaves like an inline element and lays out its content using the flexbox model.</div>
              </div>

              <div class="flex border-bottom">
                <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.hover-dark </div>
                <div class="container plr-30 ptb-20 basis-75">The element behaves like an inline element and lays out its content using the flexbox model.</div>
              </div>

              <div class="flex">
                <div class="container plr-30 ptb-20 basis-25 flex align-center border-right min-w100">.hover-light </div>
                <div class="container plr-30 ptb-20 basis-75">The element behaves like an inline element and lays out its content using the flexbox model.</div>
              </div>

              </div>
       
            </div>
          </section> -->

            <section id="hover-shadow">
              <div class="content-band" id="">
                <h2>Hover Shadow</h2>
                <p>
                  Dropshadow with 200ms transition
                </p>

                <div class="container max-w800">
                  <div class="gray-250 line-height-none p-20">
                    <div
                      class=" width-full p-20 flex justify-center pattern-diagonal-lines-sm-light"
                    >
                      <img
                        class="hover-shadow max-w300 white"
                        src="../../assets/images/img-place-holder.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >hover-shadow</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="hover-border">
              <div class="content-band" id="">
                <h2>Hover Border</h2>
                <p>
                  Hover-border applies a border to the element
                </p>

                <div class="container max-w800">
                  <div class="gray-250 line-height-none p-20">
                    <div class=" width-full p-20 flex flex-wrap gap-20 justify-center pattern-diagonal-lines-sm-light">
                      <img
                        class="hover-border max-w300 white"
                        src="../../assets/images/img-place-holder.png"
                        alt=""
                      />
                      <img
                        class="hover-border-red border-thick max-w300 white"
                        src="../../assets/images/img-place-holder.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >hover-border</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                    <br />
                    <br />
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                    >
                      container width-full </span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                      >
                        hover-border-red border-thick </span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="hover-overlay-dark">
              <div class="content-band" id="">
                <h2>Hover Overlay: Dark</h2>
                <p>
                  Hover-dark applies a black semi-transparent overly to the
                  content container
                </p>

                <div class="container max-w800">
                  <div class="gray-250 line-height-none p-20">
                    <div
                      class=" width-full p-20 flex justify-center pattern-diagonal-lines-sm-light"
                    >
                      <div class="hover-dark">
                        <img
                          class="max-w300 white"
                          src="../../assets/images/img-place-holder.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >hover-dark</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="hover-overlay-light">
              <div class="content-band" id="">
                <h2>Hover Overlay: Light</h2>
                <p>
                  Hover-light applies a white semi-transparent overly to the
                  content container
                </p>

                <div class="container max-w800">
                  <div class="gray-250 line-height-none p-20">
                    <div
                      class=" width-full p-20 flex justify-center pattern-diagonal-lines-sm-light"
                    >
                      <div class="hover-light">
                        <img
                          class="max-w300 white"
                          src="../../assets/images/img-place-holder.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >hover-light</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="hover-background-color">
              <div class="content-band" id="">
                <h2>Hover Background Color</h2>
                <p>
                  Background hover colors can be applied using the literal color
                  and numeric syntax
                </p>

                <div class="container max-w800">
                  <div class="gray-250 line-height-none p-20">
                    <div
                      class=" width-full p-20 flex justify-center pattern-diagonal-lines-sm-light"
                    >
                      <div class="white hover-red-800">
                        <img
                          class="max-w300"
                          src="../../assets/images/img-place-holder.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >hover-red-800</span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="hover-icons">
              <div class="content-band">
                <h2>Hover Icons</h2>
                <p>
                  Icon overlays can be used to denote content fuctionality types
                </p>

                <div class="container max-w800">
                  <div class="gray-250 line-height-none p-20">
                    <div class=" width-full p-20 flex flex-wrap justify-center pattern-diagonal-lines-sm-light gap-20">
                      <div class="black play-btn-icon red-600">
                        <img class="max-w300" src="../../assets/images/placeholder-trans.png" alt=""/>
                      </div>

                      <div class="red-600 external-link-icon">
                        <img
                          class="max-w300"
                          src="../../assets/images/placeholder-trans.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="gray-800 p-20 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >play-btn-icon </span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>

                  <div class="gray-800 p-20 pt-10 code font-white">
                    &#x3C;<span class="code-purple">div </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >container width-full</span
                    >"&#x3E;
                    <br />
                    <span class="pl-10"
                      >&#x3C;<span class="code-purple">img</span>
                      <span class="code-blue">class</span>="<span
                        class="code-green"
                        >external-link-icon </span
                      >" <span class="code-green">. . . </span>&#x3E;</span
                    >
                    <br />
                    &#x3C;/<span class="code-purple">div</span>&#x3E;
                  </div>
                </div>
              </div>
            </section>
          </div>

          <StickyTOC />

        </div>
      </div>


      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "flexbox",
  components: {
    StickyTOC,
    TopLevelLeftNav,
    SubFooter,
  },
};
</script>

